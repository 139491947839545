<template>
  <div id="changeAuthenticator">
    <div class="authenticator">
      <div class="title">
        <slot name="header">
          <p class="fw-800 f-16">
            {{ isAddTwoFactor ? $t('security.addAuthenticator') : $t('security.editAuthenticator') }}
          </p>
        </slot>
      </div>
      <div class="body">
        <div class="auth_guide" v-if="isMobile">
          <div class="auth_guide_name">{{ $t('security.userGuide') }}</div>
          <div :class="['auth_guide_arrow', isAuthGuideExpanded ? '' : 'rotate']" @click="toggleAuthGuideExpanded">
            <img src="@/assets/images/security/arrow.png" />
          </div>
        </div>
        <div v-if="isAuthGuideExpanded" class="process_box">
          <div class="process_items">
            <div>
              <div class="process_desc desc1">{{ $t('security.scanAuthenticatorAppTips.tips1') }}</div>
              <div class="tips_imgs tips1">
                <img src="@/assets/images/security/microgoogle.png" alt="" />
              </div>
            </div>
            <div v-if="isLocationChina">
              <div class="process_desc">{{ $t('security.scanAuthenticatorAppTips.tips2') }}</div>
              <div class="tips_imgs tips2" v-if="isLocationChina">
                <img src="@/assets/images/security/search_app.png" alt="" />
              </div>
            </div>

            <div>
              <div class="process_desc">{{ $t('security.scanAuthenticatorAppTips.tips3') }}</div>
              <div class="tips_imgs tips3">
                <img src="@/assets/images/security/codeimg.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="main_body">
          <div class="left">
            <div class="left_title">
              <div>{{ $t('security.scanWithApp') }}</div>
              <el-tooltip
                :placement="['ar'].includes(lang) ? 'left' : 'right'"
                popper-class="blue-tooltip"
                effect="light"
              >
                <img class="info_class" src="@/assets/images/security/info.png" />
                <template v-slot:content>
                  <div class="blue-tooltip-content">
                    {{ scanAppTooltip }}
                  </div>
                </template>
              </el-tooltip>
            </div>
            <img class="qr_img" :src="qrCode" alt="" />
            <div class="fw-400 f-12 gray_text text_center word_break title_setup">{{ $t('security.setupKey') }}</div>
            <div class="fw-400 f-12">{{ secretKey }}</div>
          </div>
          <div class="separator_line"></div>
          <div class="right">
            <el-form
              label-position="top"
              :model="changeAuthenticatorForm"
              :rules="rulesChangeAuthenticator"
              ref="changeAuthenticatorFormRef"
            >
              <el-form-item :label="$t('security.enterVerificationCode')" prop="verificationCode">
                <el-input
                  :class="verifyError ? 'red_box' : ''"
                  v-model="changeAuthenticatorForm.verificationCode"
                  autocomplete="off"
                >
                </el-input>
                <p v-if="verifyError" class="code_node red_text fw-400 f-12">{{ verifyError }}</p>
                <p v-else class="code_node fw-400 f-14 gray_text">{{ $t('security.authenticatorTooltip') }}</p>
              </el-form-item>
              <el-form-item prop="tickedCheckbox">
                <el-checkbox v-model="changeAuthenticatorForm.tickedCheckbox" data-testid="tncConfirm">
                  <i18n path="security.tnc" tag="div" class="gray_text">
                    <template v-slot:link>
                      <a :href="tncLink" class="blue_text" target="_blank" data-testid="clickTnc">
                        {{ $t('security.disclaimer') }}
                      </a>
                    </template>
                  </i18n>
                </el-checkbox>
              </el-form-item>
              <div class="buttons_class">
                <el-button v-if="showCancel" class="btn-default" @click="close" data-testid="cancelAuth">
                  {{ $t('common.button.cancel') }}
                </el-button>
                <el-button class="btn_blue" @click="submitForm('changeAuthenticatorForm')" data-testid="submitAuth">
                  {{ $t('common.button.confirm') }}
                </el-button>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { apiAuthStatus, apiAddTwoFactor, apiModifyTwoFactor, apiInfo } from '@/resource';
import { mapVariable, mapReverifiedBody } from '@/components/security/Security.js';
import { countryCodeEnum } from '@/constants/country';
import isMobileMixin from '@/mixins/isMobile';
export default {
  name: 'ChangeAuthenticatorComponent',
  props: {
    action: String,
    showCancel: {
      type: Boolean,
      default: true
    }
  },
  mixins: [isMobileMixin],
  watch: {
    isMobile: function (newVal) {
      if (newVal == false) {
        this.isAuthGuideExpanded = true;
      }
    }
  },
  data() {
    const checkAgreedTNC = (rule, value, callback) => {
      if (value == false) {
        return callback(new Error(' '));
      }
      return callback();
    };
    return {
      verifyError: false,
      changeAuthenticatorForm: {
        verificationCode: null,
        tickedCheckbox: false
      },
      rulesChangeAuthenticator: {
        verificationCode: [
          {
            required: true,
            message: this.$t('security.verificationRequired'),
            trigger: 'blur'
          }
        ],
        tickedCheckbox: [
          {
            validator: checkAgreedTNC,
            trigger: 'blur'
          }
        ]
      },
      qrCode: '',
      secretKey: '',
      isAuthGuideExpanded: true
    };
  },
  mounted() {
    // enable-auth-2fa或者modify-auth-2fa 需获取2fa验证器的码和密钥 /two-factor/status
    this.getApiLink();
  },
  computed: {
    isAddTwoFactor() {
      return this.action == mapVariable['addTwoFactor'];
    },
    lang() {
      return this.$store.state.common.lang;
    },
    tncLink() {
      return 'https://www.monetamarkets.com/third-party-software-disclaimer/';
    },
    scanAppTooltip() {
      return this.$store.state.common.beforeLoginCountry == countryCodeEnum.CHINA
        ? this.$t('security.scanAppTooltipForChina')
        : this.$t('security.scanAppTooltip');
    },
    isLocationChina() {
      // 居住地为中国或国籍为中国
      return this.$store.state.common.beforeLoginCountry == countryCodeEnum.CHINA;
    }
  },
  methods: {
    close() {
      this.reset();
      this.$emit('changeVisible', false);
    },
    confirm() {
      this.$emit('confirm', this.changeAuthenticatorForm.verificationCode);
      this.reset();
    },
    reset() {
      this.$refs.changeAuthenticatorFormRef.resetFields();
    },
    submitForm() {
      this.$refs.changeAuthenticatorFormRef.validate(async valid => {
        if (valid) {
          //Set before calling API to ensure it has passed to request header.
          if (this.isAddTwoFactor) {
            apiAddTwoFactor({ token: this.changeAuthenticatorForm.verificationCode }).then(resp => {
              if (resp.data.code == 647) {
                this.verifyError = this.$t('responseMsg.647');
              } else {
                this.verifyError = null;
              }
              if (resp.data.code == 0) {
                this.confirm();
              } else if (resp.data.code == 1201 || resp.data.code == 1202) {
                const usedRequest = mapReverifiedBody(this.action, resp);
                this.$emit('reauthenticate', usedRequest);
              }
            });
          } else {
            apiModifyTwoFactor(this.changeAuthenticatorForm.verificationCode).then(resp => {
              if (resp.data.code == 647) {
                this.verifyError = this.$t('responseMsg.647');
              } else {
                this.verifyError = null;
              }
              if (resp.data.code == 0) {
                this.confirm();
              } else if (resp.data.code == 1201 || resp.data.code == 1202) {
                const usedRequest = mapReverifiedBody(this.action, resp);
                this.$emit('reauthenticate', usedRequest);
              }
            });
          }
        } else return false;
      });
    },
    getApiLink() {
      apiAuthStatus()
        .then(resp => {
          this.qrCode = 'https://api.qrserver.com/v1/create-qr-code/?margin=35&data=' + resp.data.data.qrCode;
          this.secretKey = resp.data.data.secretKey;
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 切换认证指南(手机版适配)的展开与收起
    toggleAuthGuideExpanded() {
      this.isAuthGuideExpanded = !this.isAuthGuideExpanded;
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/pages/security.scss';
.label-icon {
  cursor: pointer;
}
.authenticator {
  text-align: start;
}
.title {
  padding: 0px;
  padding-bottom: 27px;
  color: #ffffff;
}
.body {
  font-size: 14px;
  font-weight: 400;
  padding: 0px;
  padding-bottom: 0px !important;
  .auth_guide {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 24px;
    margin: 12px 0;
    .auth_guide_name {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
    }
    .auth_guide_arrow {
      width: 12px;
    }
    .auth_guide_arrow.rotate {
      img {
        transform: rotate(90deg);
      }
    }
  }
  .main_body {
    display: grid;
    grid-template-columns: 42% 3% 49%;
    gap: 20px;
    .left {
      display: flex;
      flex-direction: column;
      justify-self: center;
      img {
        width: 170px;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 10px;
      }
      .left_title {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 9px;
        padding-bottom: 10px;
        justify-self: center;
        align-self: center;
        .info_class {
          width: 17px;
          height: 17px;
          padding-bottom: 0px;
        }
      }
    }
    .title_setup {
      padding-bottom: 5px;
    }
    /deep/ .el-form {
      margin-bottom: 0px !important;
    }
    .separator_line {
      border-left: 1px dotted #5b5b5b;
    }
  }
  @media (max-width: 785px) {
    .main_body {
      grid-template-columns: auto;
    }
    .separator_line {
      border-bottom: 1px dotted #5b5b5b;
    }
  }
  /deep/ .el-form-item__label {
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    padding: 0 0 12px;
  }
  /deep/ .el-input__inner {
    height: 40px;
    padding-right: 55px;
    &:focus {
      border: 1px solid #3eadff;
    }
  }
  .send_code {
    width: auto;
    text-align: center;
    cursor: pointer;
    border: none;
    background-color: transparent;
  }
  .code_node {
    margin-top: 8px;
  }
  /deep/ .el-form-item {
    margin-bottom: 20px;
  }
  /deep/ .el-checkbox__label {
    vertical-align: top;
    font-size: 12px;
    font-weight: 400;
    color: #5b5b5b;
    padding-left: 8px;
    width: calc(100% - 26px);
  }
  /deep/ .el-checkbox__input {
    vertical-align: top;
    padding-top: 3px;
  }
  /deep/ .is-error {
    .el-form-item__content {
      .code_node {
        display: none;
      }
    }
  }
  .word_break {
    word-break: normal;
  }
  .buttons_class {
    display: flex;
    gap: 20px;
    /deep/ .el-button {
      border-radius: 8px;
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      padding: 8px 12px;
    }
  }
}
</style>

<style lang="scss">
// 处理提示框的颜色
.blue-tooltip.el-tooltip__popper {
  max-width: 180px !important;
  background: #2f3547 !important;
  box-shadow: 2px 4px 5px 0px #0000000d;
  border: none !important;
  & > div {
    color: #ffffff !important;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    max-width: 180px !important;
    height: unset;
  }
}

// 处理箭头的颜色
.blue-tooltip.el-tooltip__popper .popper__arrow {
  border: 1px solid #2F3547 !important;
  background: #2F3547 !important;
  box-shadow: 2px 4px 5px 0px #0000000D;
}

.blue-tooltip.el-tooltip__popper[x-placement^=right] .popper__arrow{
  border-right-color: #2F3547!important;
}
.blue-tooltip.el-tooltip__popper[x-placement^=right] .popper__arrow:after{
  border-right-color: #2F3547!important;
}
.blue-tooltip.el-tooltip__popper[x-placement^=left] .popper__arrow{
  border-left-color: #2F3547!important;
}
.blue-tooltip.el-tooltip__popper[x-placement^=left] .popper__arrow:after{
  border-left-color: #2F3547!important;
}
</style>
