export default {
  data() {
    return {
      isMobile: false,
    }
  },
  mounted() {
    window.addEventListener('resize', this.getMedia)
    this.getMedia()
  },
  beforeDestroy() {
    // Unregister the event listener before destroying this Vue instance
    window.removeEventListener('resize', this.getMedia)
  },
  methods: {
    getMedia() {
      this.isMobile = window.matchMedia('(max-width: 767px)').matches
    },
  },
}
